import React from "react";
import { Link } from "gatsby";

import Layout from "../components/Layout.js";

export default () => {
  return (
    <Layout>
      <h2 style={{ textAlign: "center" }}>
        Uh oh... we couldn't find this page!
      </h2>
      <p style={{ textAlign: "center" }}>
        <Link to="/" style={{ textDecoration: "underline" }}>
          Back to safety!
        </Link>
      </p>
    </Layout>
  );
};
